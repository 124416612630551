import { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import AddIcon from "@mui/icons-material/Add";
import Divider from "@mui/material/Divider";
import Paper from "@mui/material/Paper";
import Tab from "@mui/material/Tab";
import Button from "@mui/material/Button";
import Collapse from "@mui/material/Collapse";
import LinearProgress from "@mui/material/LinearProgress";
import { StyledTabs } from "core/components/StyledTabs";
import { Loading } from "core/components/Loading";
import { CustomLink } from "core/components/CustomLink";
import { ErrorComponent } from "core/components/ErrorComponent";
import { GridAreaContainer } from "core/components/GridAreaContainer";
import { useTranslation } from "react-i18next";
import { useNewsEventCategories } from "features/NewsEvents/hooks";
import { NewsWidgetTile } from "./NewsWidgetTile";
import { useNewsWidgetArticles } from "./useNewsWidgetArticles";

export function NewsWidget() {
  const {
    data: categoryData,
    isLoading: areCategoriesLoading,
    isError: areCategoriesError,
  } = useNewsEventCategories(true);

  const [tabValue, setTabValue] = useState<null | string>(
    categoryData?.items?.[0]?.id ?? null
  );

  const { items, isLoading, isError } = useNewsWidgetArticles(tabValue, 3);

  const displayNoNewsMessage = !isLoading && !isError && items.length === 0;

  function handleTabChange(_: any, newTabValue: string) {
    setTabValue(newTabValue);
  }
  useEffect(() => {
    if (
      categoryData &&
      !areCategoriesLoading &&
      !areCategoriesError &&
      Array.isArray(categoryData.items) &&
      categoryData.items.length > 0
    ) {
      const initialSelectedTab = categoryData?.items[0]?.id;
      if (typeof initialSelectedTab === "string") {
        setTabValue(initialSelectedTab);
      }
    }
  }, [categoryData, areCategoriesLoading, areCategoriesError]);

  const { t } = useTranslation("NewsEvents", {
    keyPrefix: "Next:NewsEvents:NewsWidget",
  });

  if (!categoryData || areCategoriesLoading) {
    return (
      <GridAreaContainer gridArea="news" title={t("gridAreaContainerTitle")}>
        <Paper sx={{ p: 3, height: "100%", minHeight: "970px" }}>
          <Loading textDisabled />
        </Paper>
      </GridAreaContainer>
    );
  }
  const displayNoCategoriesMessage = categoryData.items?.length === 0;

  return (
    <GridAreaContainer
      gridArea="news"
      title={t("gridAreaContainerTitle")}
      BottomRightSlot={
        <Button
          startIcon={<AddIcon />}
          color="primary"
          href="/news"
          LinkComponent={CustomLink}
        >
          {t("seeAllNews")}
        </Button>
      }
    >
      <Paper
        sx={{
          p: 3,
          marginX: "auto",
          width: "min(900px,100%)",
          height: "100%",
          minHeight: "990px",
        }}
      >
        <Stack>
          <Box>
            {displayNoCategoriesMessage && (
              <Typography variant="body1" color="text.secondary">
                {t("noCategories")}
              </Typography>
            )}
            {tabValue !== null && (
              <StyledTabs
                sx={{ maxWidth: "100%", "& .MuiTab-root": { flex: 1 } }}
                value={tabValue}
                onChange={handleTabChange}
                variant="scrollable"
              >
                {categoryData?.items?.map(({ title, id }) => (
                  <Tab key={id} value={id} label={title} />
                ))}
              </StyledTabs>
            )}
            <Divider sx={{ mb: 2 }} />
            <Collapse in={isLoading} key="loading">
              <LinearProgress />
            </Collapse>
            {isError && <ErrorComponent />}
            {displayNoNewsMessage && (
              <Typography variant="body1" color="text.secondary">
                {t("noNews")}
              </Typography>
            )}
          </Box>
          <Stack flex={1}>
            {items.map(
              (
                {
                  blurb,
                  id,
                  publishedTime,
                  title,
                  banner,
                  articleType,
                  isImportant,
                },
                index
              ) => (
                <NewsWidgetTile
                  reverse={Boolean(index % 2)}
                  key={id}
                  id={id ?? ""}
                  title={title ?? ""}
                  blurb={blurb ?? ""}
                  publishedTime={publishedTime ?? ""}
                  articleType={
                    articleType ?? { id: "", title: "", description: "" }
                  }
                  bannerBlobName={banner?.blobName ?? ""}
                  bannerAltText={banner?.altText ?? ""}
                  blurDataUrl={banner?.blurDataUrl ?? ""}
                  isImportant={isImportant}
                />
              )
            )}
          </Stack>
        </Stack>
      </Paper>
    </GridAreaContainer>
  );
}
