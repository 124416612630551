import { FormEventHandler } from "react";
import { useTranslation } from "react-i18next";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import { UseFormReturn } from "react-hook-form";
import Stack from "@mui/material/Stack";
import { ReactHookDateRangePicker } from "core/components/ReactHookFormComponents/ReactHookDateRangePicker";
import { NewDocumentFormValues } from "features/DMS/entities";
import { ALL_VALUE } from "config";
import { DMSFormButtons } from "../DMSFormButtons";
import { LibraryFormDetailsSkeleton } from "../LibraryFormDetailsSkeleton";
import { ManagePaper } from "../ManagePaper";
import { DocumentTranslationsFields } from "../TranslationsFields";
import { DmsViewablePermissionsManagement } from "./DmsViewablePermissionsManagement";

export interface NewDocumentFormProps {
  componentName: "DocumentAdd" | "DocumentEdit";
  formMethods: UseFormReturn<NewDocumentFormValues>;
  cancelOnClick: () => void;
  clearOnClick?: () => void;
  onSubmit: FormEventHandler<HTMLFormElement>;
  docLibraryId: string;
  isLoading?: boolean;
}
export function DocumentForm({
  componentName,
  formMethods,
  onSubmit,
  docLibraryId,
  clearOnClick,
  cancelOnClick,
  isLoading,
}: NewDocumentFormProps) {
  const { t } = useTranslation("DocumentManagementSystem");
  const testId =
    componentName === "DocumentAdd"
      ? "document-add-form"
      : "document-edit-form";
  const { isSubmitting } = formMethods.formState;

  return (
    <Box data-testid={testId} component="form" onSubmit={onSubmit}>
      <Typography variant="h3" textAlign="center" color="primary.main" my={3}>
        {t(`Next:DocumentManagementSystem:${componentName}.title`)}
      </Typography>
      <ManagePaper
        sectionTitle={t(
          `Next:DocumentManagementSystem:DocumentForm.detailsTitle`
        )}
      >
        {isLoading ? (
          <LibraryFormDetailsSkeleton />
        ) : (
          <>
            <Stack spacing={2}>
              <DocumentTranslationsFields />
              <ReactHookDateRangePicker
                control={formMethods.control}
                fromName="availableFrom"
                toName="availableTo"
                fromLabel={t(
                  "Next:DocumentManagementSystem:DocumentForm.availableFrom"
                )}
                toLabel={t(
                  "Next:DocumentManagementSystem:DocumentForm.availableTo"
                )}
              />
            </Stack>
            <Divider sx={{ mt: 4, mb: 2 }} />
            <DmsViewablePermissionsManagement
              formMethods={formMethods}
              docLibraryId={docLibraryId}
            />
          </>
        )}
      </ManagePaper>
      <DMSFormButtons
        mode={componentName === "DocumentAdd" ? "add" : "edit"}
        clearOnClick={clearOnClick}
        formType="DocumentForm"
        cancelOnClick={cancelOnClick}
        isSubmitting={isSubmitting}
      />
    </Box>
  );
}

export const defaultValues: NewDocumentFormValues = {
  availableFrom: new Date(),
  availableTo: null,
  attachments: {},
  isVisibleToShops: false,
  isDocumentUpload: false,
  translations: {
    en: {
      transKey: "en",
      name: "",
      description: "",
      tags: [],
      isDefault: true,
    },
  },
  permissions: {
    toggle: "departments",
    isVisibleToShops: true,
    brands: [],
    regions: [],
    departments: [ALL_VALUE],
    corporateRoles: [ALL_VALUE],
    shops: [ALL_VALUE],
    shopRoles: [ALL_VALUE],
  },
};
