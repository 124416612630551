import { useTranslation } from "react-i18next";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import { UseFormReturn } from "react-hook-form";
import Stack from "@mui/material/Stack";
import { NewDocumentFormValues } from "features/DMS/entities";
import { ReactHookCheckbox } from "core/components/ReactHookFormComponents/ReactHookCheckbox";
import { ReactHookViewablePermissionMultiselect } from "core/components/ReactHookFormComponents/ReactHookViewablePermissionMultiselect";
import { SwitchySwitch } from "core/components/SwitchySwitch";
import { ComponentSwitcher } from "core/components/ComponentSwitcher";
import { ALL_VALUE } from "config";
import { useViewableDMSPermissionOptions } from "../PermissionManagement/ViewablePermissionsManagement";
import {
  filterDepartmentOptionsBySelectedBrands,
  filterRegionOptionsBySelectedBrands,
} from "../PermissionManagement/ViewablePermissionsManagement/utils";
import { InvalidSelectionWarning } from "./InvalidSelectionWarning";

export function DmsViewablePermissionsManagement({
  formMethods,
  docLibraryId,
}: {
  formMethods: UseFormReturn<NewDocumentFormValues>;
  docLibraryId: string;
}) {
  const { t } = useTranslation("DocumentManagementSystem", {
    keyPrefix: "Next:DocumentManagementSystem:ReactHookDMSViewablePermissions",
  });

  const [
    selectedBrands,
    selectedRegions,
    selectedDepartments,
    toggle,
    isVisibleToShops,
  ] = formMethods.watch([
    "permissions.brands",
    "permissions.regions",
    "permissions.departments",
    "permissions.toggle",
    "permissions.isVisibleToShops",
  ]);

  const {
    brandOptions,
    corporateRoleOptions,
    departmentBrandMap = {},
    departmentOptions,
    isLoading,
    regionOptions,
    shopOptions,
    shopRoleOptions,
    brandRegionMap,
  } = useViewableDMSPermissionOptions(
    docLibraryId,
    selectedBrands,
    selectedRegions
  );

  const filteredRegionOptions = filterRegionOptionsBySelectedBrands(
    selectedBrands,
    regionOptions,
    brandRegionMap
  );
  const filteredDepartmentOptions = filterDepartmentOptionsBySelectedBrands(
    selectedBrands,
    departmentOptions,
    departmentBrandMap
  );

  function brandChangeCallBack() {
    formMethods.setValue("permissions.regions", []);
    formMethods.setValue("permissions.departments", [ALL_VALUE]);
    formMethods.setValue("permissions.shops", [ALL_VALUE]);
  }

  function regionChangeCallBack() {
    formMethods.setValue("permissions.shops", [ALL_VALUE]);
  }

  return (
    <Stack gap={2}>
      <Typography variant="h5" sx={{ mt: 2 }}>
        {t("visibility")}
      </Typography>
      <InvalidSelectionWarning
        selectedBrands={selectedBrands}
        selectedRegions={selectedRegions}
        selectedDepartments={selectedDepartments}
        brandRegionMap={brandRegionMap}
        departmentBrandMap={departmentBrandMap}
      />
      <Stack direction="row" spacing={2}>
        <ReactHookViewablePermissionMultiselect
          sx={{ flex: 1 }}
          testId="brand-select"
          required
          control={formMethods.control}
          name="permissions.brands"
          label={t("brands")}
          options={brandOptions}
          loading={isLoading}
          onChangeCallback={brandChangeCallBack}
        />
        <ReactHookViewablePermissionMultiselect
          sx={{ flex: 1 }}
          testId="region-select"
          required
          control={formMethods.control}
          name="permissions.regions"
          label={t("regions")}
          options={filteredRegionOptions}
          loading={isLoading}
          onChangeCallback={regionChangeCallBack}
        />
      </Stack>
      <Divider textAlign="left" sx={{ mt: 2 }}>
        <Typography variant="body1">{t("corporate")}</Typography>
      </Divider>
      <SwitchySwitch
        testId="department-role-toggle"
        isPositionOne={toggle === "departments"}
        positionOneText={t("switchDepartmentText")}
        positionTwoText={t("switchRoleText")}
        switchOnChange={(_, checked) => {
          const newTogglePosition = checked ? "roles" : "departments";
          formMethods.setValue("permissions.toggle", newTogglePosition);
        }}
      />
      <ComponentSwitcher
        switchPosition={toggle === "departments"}
        ComponentOne={
          <ReactHookViewablePermissionMultiselect
            sx={{ flex: 1 }}
            testId="department-select"
            required={toggle === "departments"}
            control={formMethods.control}
            name="permissions.departments"
            label={t("departments")}
            options={filteredDepartmentOptions}
            loading={isLoading}
          />
        }
        ComponentTwo={
          <ReactHookViewablePermissionMultiselect
            sx={{ flex: 1 }}
            required={toggle === "roles"}
            testId="corporate-role-select"
            control={formMethods.control}
            name="permissions.corporateRoles"
            label={t("corporateRoles")}
            options={corporateRoleOptions}
            loading={isLoading}
          />
        }
      />
      <Divider textAlign="left" sx={{ mt: 2 }}>
        <Typography variant="body1">{t("shop")}</Typography>
      </Divider>
      <ReactHookCheckbox
        labelPlacement="end"
        control={formMethods.control}
        name="permissions.isVisibleToShops"
        label={t("isVisibleToShops")}
        testId="is-visible-to-shops-checkbox"
      />

      <Stack direction="row" spacing={2}>
        <ReactHookViewablePermissionMultiselect
          sx={{ flex: 1 }}
          testId="shop-select"
          control={formMethods.control}
          required={isVisibleToShops}
          disabled={!isVisibleToShops}
          name="permissions.shops"
          label={t("shops")}
          options={shopOptions}
          loading={isLoading}
        />
        <ReactHookViewablePermissionMultiselect
          sx={{ flex: 1 }}
          testId="shop-role-select"
          required={isVisibleToShops}
          control={formMethods.control}
          disabled={!isVisibleToShops}
          name="permissions.shopRoles"
          label={t("shopRoles")}
          options={shopRoleOptions}
          loading={isLoading}
        />
      </Stack>
    </Stack>
  );
}
