import Typography from "@mui/material/Typography";
import { useToaster } from "core/hooks/useToaster";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { LibraryFormValues } from "features/DMS/entities";
import { arrayToObject } from "core/utils/arrayToObject";
import {
  useLibraryById,
  useDMSFormErrorHandler,
  useManageableLibraries,
  useInfiniteDocuments,
  useCategories,
} from "features/DMS/hooks";
import { useCentrixFetch } from "core/hooks/useCentrixFetch";
import { LibraryForm, defaultValues } from "./LibraryForm";
import { convertLibraryFormValuesToDocLibraryCreateUpdateDto } from "./convertLibraryFormValuesToDocLibraryCreateUpdateDto";

interface LibraryEditProps {
  cancelOnClick: () => void;
  id: string;
  canManageLibraryContents: boolean;
}

export function LibraryEdit({
  cancelOnClick,
  id,
  canManageLibraryContents,
}: LibraryEditProps) {
  const { data, isLoading, isError, mutate } = useLibraryById(id);
  const { mutate: mutateDocs } = useInfiniteDocuments({
    parentId: id,
    mode: "manage",
    canManageLibraryContents,
  });
  const { mutate: mutateCategories } = useCategories({
    mode: "manage",
    parentId: id,
    canManageLibraryContents,
  });
  const formMethods = useForm<LibraryFormValues>({
    defaultValues,
    mode: "onChange",
  });
  const { isSubmitting } = formMethods.formState;
  const { mutate: mutateManageableLibraries } = useManageableLibraries();
  const { errorToast, successToast } = useToaster();
  const centrixFetch = useCentrixFetch();
  const { errorHandler, scrollToRef } = useDMSFormErrorHandler(
    formMethods,
    "LibraryForm"
  );

  useEffect(() => {
    if (!isLoading && !isError && data) {
      const { translations: translationsArray, ...rest } =
        structuredClone(data);
      if (!Array.isArray(translationsArray)) {
        return;
      }
      const translations = arrayToObject(
        translationsArray.map((translation) => ({
          ...translation,
        })),
        "transKey"
      );
      const editFormData = {
        translations,
        ...rest,
      } as unknown as LibraryFormValues;
      formMethods.reset(editFormData);
    }
  }, [data, isLoading, isError, formMethods]);

  const { handleSubmit } = formMethods;
  const { t } = useTranslation("DocumentManagementSystem");
  const errorMessage = t("Next:DocumentManagementSystem:LibraryEdit.PUTError");
  const successMessage = t(
    "Next:DocumentManagementSystem:LibraryEdit.PUTSuccess"
  );

  async function onFormSubmit(submitData: LibraryFormValues) {
    const body =
      convertLibraryFormValuesToDocLibraryCreateUpdateDto(submitData);
    const libraryEditResponse = await centrixFetch({
      method: "put",
      path: "/api/app/doc-library/{id}",
      parameters: { path: { id } },
      body,
    });
    if (!libraryEditResponse.ok) {
      errorToast(errorMessage);
      return;
    }

    await Promise.allSettled([
      mutateDocs(),
      mutateCategories(),
      mutate(),
      mutateManageableLibraries(),
    ]);
    successToast(successMessage);
    cancelOnClick();
  }

  const onSubmit = handleSubmit(onFormSubmit, errorHandler);
  return (
    <>
      <Typography
        variant="h3"
        textAlign="center"
        color="primary.main"
        my={3}
        ref={scrollToRef}
      >
        {t("Next:DocumentManagementSystem:LibraryEdit.title")}
      </Typography>
      <LibraryForm
        mode="edit"
        isLoading={isLoading}
        translations={data?.translations ?? []}
        formMethods={formMethods}
        onSubmit={onSubmit}
        isSubmitting={isSubmitting}
        cancelOnClick={cancelOnClick}
      />
    </>
  );
}
