import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import { useTranslation } from "react-i18next";
import { useNavigate } from "core/hooks/useNavigate";
import { useToaster } from "core/hooks/useToaster";
import { useManageableLibraries } from "features/DMS/hooks";
import { useCentrixFetch } from "core/hooks/useCentrixFetch";

interface LibraryDeleteProps {
  id: string;
  cancelOnClick: () => void;
}

export function LibraryDelete({
  cancelOnClick = () => {},
  id,
}: LibraryDeleteProps) {
  const { t } = useTranslation("DocumentManagementSystem", {
    keyPrefix: "Next:DocumentManagementSystem:LibraryDelete",
  });
  const { errorToast, successToast } = useToaster();
  const { mutate: mutateManageableLibraries } = useManageableLibraries();
  const centrixFetch = useCentrixFetch();
  const errorMessage = t("errorToastMessage");
  const successMessage = t("successToastMessage");
  const navigate = useNavigate();

  async function onDeleteCLick() {
    const deleteResponse = await centrixFetch({
      method: "delete",
      path: "/api/app/doc-library/{id}",
      parameters: { path: { id } },
    });
    if (!deleteResponse.ok) {
      errorToast(errorMessage);
      return;
    }
    await mutateManageableLibraries((data) => {
      if (!data || !Array.isArray(data.items)) {
        return { items: [], totalCount: 0 };
      }
      const newItems = data.items.filter((library) => library.id !== id);
      return { items: newItems, totalCount: newItems.length };
    });
    successToast(successMessage);
    await navigate("/dms/library");
    cancelOnClick();
  }
  return (
    <Box>
      <Typography
        variant="h5"
        mx="auto"
        mb={4}
        textAlign="center"
        maxWidth="65ch"
      >
        {t("warningMessage")}
      </Typography>
      <Typography
        variant="h5"
        mx="auto"
        mb={4}
        textAlign="center"
        maxWidth="65ch"
      >
        {t("confirmMessage")}
      </Typography>
      <Stack spacing={2} direction="row" justifyContent="center">
        <Button
          variant="outlined"
          color="primary"
          onClick={cancelOnClick}
          size="large"
        >
          {t("cancelButton")}
        </Button>
        <Button
          variant="contained"
          color="error"
          size="large"
          onClick={onDeleteCLick}
        >
          {t("deleteButton")}
        </Button>
      </Stack>
    </Box>
  );
}
