import { useProcessBrandRegionData } from "core/hooks/useProcessBrandRegionData";
import { ALL_VALUE } from "config";
import { useTranslation } from "react-i18next";
import { useCentrixApi } from "core/hooks/useCentrixApi";

export function useDMSBrandRegionOptions(
  mode: "library" | "document",
  docLibraryId?: string
) {
  const { t } = useTranslation("DocumentManagementSystem", {
    keyPrefix: "Next:DocumentManagementSystem:useBrandRegionOptions",
  });
  const processBrandRegionData = useProcessBrandRegionData();
  const isLibraryMode = mode === "library";
  const isDocumentMode = !isLibraryMode;
  if (isDocumentMode && !docLibraryId) {
    throw new Error('docLibraryId required in "document" mode');
  }

  const {
    data: libraryData,
    isError: isLibraryDataError,
    isLoading: isLibraryDataLoading,
  } = useCentrixApi({
    shouldFetch: isLibraryMode,
    path: "/api/shared-entities/organization-unit/brands",
  });

  const {
    data: documentData,
    isError: isDocumentDataError,
    isLoading: isDocumentDataLoading,
  } = useCentrixApi({
    shouldFetch: isDocumentMode,
    path: "/api/app/doc-library/{id}/available-brands",
    parameters: { path: { id: docLibraryId as string } },
  });

  const data = isLibraryMode ? libraryData : documentData;

  const {
    brandOptions = [],
    regionOptions = [],
    brandRegionMap = {},
    brandCodes = [],
  } = processBrandRegionData(data);

  if (mode === "library") {
    brandOptions.unshift({ label: t("allBrands"), value: ALL_VALUE });
    regionOptions.unshift({ label: t("allRegions"), value: ALL_VALUE });
  }

  return {
    brandOptions,
    regionOptions,
    brandRegionMap,
    brandCodes,
    data,
    isError: isLibraryMode ? isLibraryDataError : isDocumentDataError,
    isLoading: isLibraryMode ? isLibraryDataLoading : isDocumentDataLoading,
  };
}
