import { LibraryFormValues, DocLibraryCreateUpdateDto } from "../entities";

export function convertLibraryFormValuesToDocLibraryCreateUpdateDto(
  input: LibraryFormValues
): DocLibraryCreateUpdateDto {
  const translationEntries = Object.values(input.translations);
  const translations: DocLibraryCreateUpdateDto["translations"] =
    translationEntries
      .filter(
        (entry): entry is typeof entry & { name: string } =>
          typeof entry.name === "string"
      )
      .map((entry) => ({
        name: entry.name,
        description: entry.description || undefined,
        isDefault: entry.isDefault,
        transKey: entry.transKey || "en",
      }));

  const permissions: DocLibraryCreateUpdateDto["permissions"] =
    input.permissions
      ?.filter(
        (entry): entry is typeof entry & { brand: string; region: string } =>
          typeof entry.brand === "string" && typeof entry.region === "string"
      )
      .map((entry) => ({
        brand: entry.brand,
        region: entry.region,
        departmentName: entry.departmentName,
        roleId: entry.roleId,
      })) || [];
  if (permissions.length === 0) {
    throw new Error("permissions cannot be empty");
  }
  return {
    permissions,
    translations,
    tags: input.tags ?? undefined,
    menuItemId: input.menuItemId,
  };
}
