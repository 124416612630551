import { SelectOption } from "core/entities";

export function isSelectOption(item: any): item is SelectOption {
  return (
    item &&
    typeof item === "object" &&
    "label" in item &&
    "value" in item &&
    typeof item.label === "string" &&
    typeof item.value === "string"
  );
}
