import { components } from "resources/api-schema";

export enum SearchableEntityType {
  DocLibrary = "DocLibrary",
  DocDocument = "DocDocument",
}

export type DMSSortingOption = `${"name" | "creationTime"} ${"asc" | "desc"}`;

export type DMSFormType =
  | "LibraryForm"
  | "CategoryForm"
  | "SubCategoryForm"
  | "DocumentForm";

export type TranslationKey =
  components["schemas"]["Fixhub.FixnDocumentManagementSystem.TranslationKey"];

export const TranslationKeys: TranslationKey[] = [
  "en",
  "fr",
  "es",
  "hi",
  "it",
  "sk",
  "sl",
  "tr",
  "ar",
  "de-DE",
];

export const DEFAULT_TRANSLATION_KEY: TranslationKey = "en";

export type DocCategoryDto =
  components["schemas"]["Fixhub.FixnDocumentManagementSystem.DocCategories.DocCategoryDto"];

export type DocLibraryDto =
  components["schemas"]["Fixhub.FixnDocumentManagementSystem.DocLibraries.DocLibraryDto"];

export type DocTranslationDto =
  components["schemas"]["Fixhub.FixnDocumentManagementSystem.DocTranslations.DocTranslationDto"];

export type DocDocumentDto =
  components["schemas"]["Fixhub.FixnDocumentManagementSystem.DocDocuments.DocDocumentDto"];

export type DocDocumentAttachmentDto =
  components["schemas"]["Fixhub.FixnDocumentManagementSystem.DocDocuments.DocDocumentAttachmentDto"];

export type DocumentPermissionItemDto =
  components["schemas"]["Fixhub.FixnDocumentManagementSystem.DocPermissions.DocPermissionItemDto"];

export type DocDocumentCreateUpdateDto =
  components["schemas"]["Fixhub.FixnDocumentManagementSystem.DocDocuments.DocDocumentCreateUpdateDto"];

export type DocDocumentAttachmentCreateUpdateDto =
  components["schemas"]["Fixhub.FixnDocumentManagementSystem.DocDocuments.DocDocumentAttachmentCreateUpdateDto"];

export type DocLibraryPermissionDto =
  components["schemas"]["Fixhub.FixnDocumentManagementSystem.DocPermissions.DocLibraryPermissionDto"];

export type DocLibraryCreateUpdateDto =
  components["schemas"]["Fixhub.FixnDocumentManagementSystem.DocLibraries.DocLibraryCreateUpdateDto"];

export type CorporatePermission = {
  id: string;
  brand?: string | undefined;
  region?: string | undefined;
  roleId?: string | undefined;
  departmentName?: string | undefined;
};

export type ShopPermission = {
  id: string;
  brand?: string | undefined;
  region?: string | undefined;
  shopRoleId?: string | undefined;
  fnShopId?: string | undefined;
};

export type TranslationsObject = {
  [key in TranslationKey]?: DocTranslationDto;
};

export type AttachmentsObject = {
  [key in TranslationKey]?: DocDocumentAttachmentDto;
};

export type LibraryFormValues = {
  menuItemId?: string | undefined;
  tags?: string[] | undefined;
  permissions: DocLibraryDto["permissions"];
  translations: TranslationsObject;
};

export type CategoryFormValues = {
  id?: string | undefined;
  translations: TranslationsObject;
};

export type DmsDocumentFormPermissionEntry = {
  id?: string | undefined;
  brand: string;
  region: string;
  roleId?: string | undefined;
  departmentName?: string | undefined;
  shopRoleId?: string | undefined;
  shopId?: string | undefined;
  shopName?: string | undefined;
};

export type DocumentFormValues = {
  availableFrom?: Date | undefined | null;
  availableTo?: Date | undefined | null;
  attachments?: AttachmentsObject;
  translations?: TranslationsObject;
  isDocumentUpload?: boolean;
  isVisibleToShops?: boolean;
  permissions?: DmsDocumentFormPermissionEntry[];
};

export interface ViewablePermissionsFormValues {
  toggle: "departments" | "roles";
  isVisibleToShops: boolean;
  brands: string[];
  regions: string[];
  departments: string[];
  corporateRoles: string[];
  shops: string[];
  shopRoles: string[];
}

export type NewDocumentFormValues = {
  availableFrom: Date | null;
  availableTo: Date | null;
  attachments?: AttachmentsObject;
  translations: TranslationsObject;
  isDocumentUpload: boolean;
  isVisibleToShops: boolean;
  permissions: ViewablePermissionsFormValues;
};

export type WidgetTabValues = "publishTime" | "lastModificationTime";

export type DMSWidgetListItemParent = {
  id: string;
  href?: string;
  name: string;
};

export interface DMSWidgetLinkListItemData {
  id: string;
  type: "link";
  name: string;
  publishTime?: string | undefined | null;
  lastModificationTime?: string | undefined | null;
  parents: DMSWidgetListItemParent[];
  tags: string[];
  documentLink: string;
}

export interface DMSWidgetFileListItemData {
  id: string;
  type: "file";
  name: string;
  publishTime: string | null | undefined;
  lastModificationTime?: string | undefined | null;
  parents: DMSWidgetListItemParent[];
  tags: string[];
  fileId: string;
  fileName: string;
}

export type DMSWidgetListItemData =
  | DMSWidgetLinkListItemData
  | DMSWidgetFileListItemData;
