import { useState } from "react";
import Typography from "@mui/material/Typography";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { LibraryFormValues } from "features/DMS/entities";
import { useToaster } from "core/hooks/useToaster";
import {
  useDMSFormErrorHandler,
  useManageableLibraries,
} from "features/DMS/hooks";
import { useCentrixFetch } from "core/hooks/useCentrixFetch";
import { LibraryForm, defaultValues } from "./LibraryForm";
import { convertLibraryFormValuesToDocLibraryCreateUpdateDto } from "./convertLibraryFormValuesToDocLibraryCreateUpdateDto";

export interface LibraryAddProps {
  cancelOnClick: () => void;
}

export function DmsLibraryAdd({ cancelOnClick }: LibraryAddProps) {
  const formMethods = useForm<LibraryFormValues>({
    defaultValues,
    mode: "onChange",
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { handleSubmit } = formMethods;
  const { mutate } = useManageableLibraries();
  const centrixFetch = useCentrixFetch();
  const { successToast, errorToast } = useToaster();
  const { t } = useTranslation("DocumentManagementSystem");
  const { errorHandler, scrollToRef } = useDMSFormErrorHandler(
    formMethods,
    "LibraryForm"
  );
  const successMessage = t(
    "Next:DocumentManagementSystem:LibraryAdd.POSTSuccess"
  );
  const errorMessage = t("Next:DocumentManagementSystem:LibraryAdd.POSTError");
  async function handleFormSubmit(data: LibraryFormValues) {
    setIsSubmitting(true);
    const body = convertLibraryFormValuesToDocLibraryCreateUpdateDto(data);
    const libraryResponse = await centrixFetch({
      method: "post",
      path: "/api/app/doc-library",
      body,
    });
    if (!libraryResponse.ok) {
      errorToast(errorMessage);
      return;
    }
    await mutate();
    successToast(successMessage);
    cancelOnClick();
  }

  function clearOnClick() {
    formMethods.reset(defaultValues);
  }

  return (
    <>
      <Typography
        variant="h3"
        textAlign="center"
        color="primary.main"
        my={3}
        ref={scrollToRef}
      >
        {t("Next:DocumentManagementSystem:LibraryAdd.title")}
      </Typography>
      <LibraryForm
        isSubmitting={isSubmitting}
        formMethods={formMethods}
        onSubmit={handleSubmit(handleFormSubmit, errorHandler)}
        cancelOnClick={cancelOnClick}
        clearOnClick={clearOnClick}
      />
    </>
  );
}
