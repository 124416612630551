import DeleteIcon from "@mui/icons-material/Delete";
import List, { ListProps } from "@mui/material/List";
import IconButton from "@mui/material/IconButton";
import ListItem from "@mui/material/ListItem";
import Typography from "@mui/material/Typography";
import ListItemText from "@mui/material/ListItemText";
import Box from "@mui/material/Box";
import ListItemIcon from "@mui/material/ListItemIcon";
import Collapse from "@mui/material/Collapse";
import { CorporatePermission } from "features/DMS/entities";
import { useTranslation } from "react-i18next";
import { TransitionGroup } from "react-transition-group";
import { ALL_VALUE } from "config";
import { useRoleOptions } from "core/hooks/useRoleOptions";

export interface SelectedPermissionProps extends ListProps {
  selectedPermissions: CorporatePermission[];
  isError?: boolean;
  onDelete: (id: string) => void;
}

export function CorporateSelectedPermissionsList({
  isError,
  selectedPermissions,
  onDelete,
  ...listProps
}: SelectedPermissionProps) {
  const { idRoleMap } = useRoleOptions("Corporate");
  const { t } = useTranslation("DocumentManagementSystem", {
    keyPrefix: "Next:DocumentManagementSystem:CorporateSelectedPermissionsList",
  });
  const { t: tCore } = useTranslation("Fixhub");
  return (
    <List {...listProps}>
      <TransitionGroup>
        {!selectedPermissions?.length && (
          <Collapse key="requiredMessage">
            <Typography
              sx={{
                pl: 2,
                color: (theme) =>
                  isError
                    ? theme.palette.error.main
                    : theme.palette.text.primary,
              }}
              variant="body1"
            >
              {t("requiredMessage")}
            </Typography>
          </Collapse>
        )}

        {selectedPermissions.map(
          ({ id, brand, region, roleId, departmentName }) => {
            const key = id || `${brand}-${region}-${departmentName ?? roleId}`;
            const brandName =
              brand === ALL_VALUE
                ? t("allBrands")
                : tCore(`Brand:BrandName.${brand}`);
            const regionName =
              region === ALL_VALUE
                ? t("allRegions")
                : tCore(`Next:Core:RegionCode.${region}`);
            const departmentDisplayName =
              departmentName === ALL_VALUE
                ? t("allDepartments")
                : departmentName;
            const roleName =
              roleId === ALL_VALUE
                ? t("allRoles")
                : roleId && (idRoleMap[roleId] || roleId);
            return (
              <Collapse key={key}>
                <ListItem
                  key={id}
                  sx={{
                    mb: 1,
                    bgcolor: "background.paper",
                    borderRadius: 1,
                    border: (theme) => `1px solid ${theme.palette.divider}`,
                  }}
                >
                  <Box
                    display="grid"
                    flex={1}
                    gap={1}
                    gridTemplateColumns="repeat(4,1fr)"
                  >
                    <ListItemText>
                      <Typography variant="subtitle2" color="text.secondary">
                        {t("brand")}
                      </Typography>
                      <Typography variant="body1">{brandName}</Typography>
                    </ListItemText>
                    <ListItemText>
                      <Typography variant="subtitle2" color="text.secondary">
                        {t("region")}
                      </Typography>
                      <Typography variant="body1">{regionName}</Typography>
                    </ListItemText>{" "}
                    <ListItemText>
                      <Typography variant="subtitle2" color="text.secondary">
                        {t("department")}
                      </Typography>
                      <Typography variant="body1">
                        {departmentDisplayName ?? t("NA")}
                      </Typography>
                    </ListItemText>{" "}
                    <ListItemText>
                      <Typography variant="subtitle2" color="text.secondary">
                        {t("role")}
                      </Typography>
                      <Typography variant="body1">
                        {roleName ?? t("NA")}
                      </Typography>
                    </ListItemText>
                  </Box>
                  <ListItemIcon>
                    <IconButton
                      onClick={() => {
                        if (id) {
                          onDelete(id);
                        }
                      }}
                    >
                      <DeleteIcon color="primary" />
                    </IconButton>
                  </ListItemIcon>
                </ListItem>
              </Collapse>
            );
          }
        )}
      </TransitionGroup>
    </List>
  );
}
