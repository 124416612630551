import { exhaustiveCheck } from "core/utils/exhaustiveCheck";
import { ListItemProps } from "@mui/material/ListItem";
import { MyLinksIcon } from "features/MyLinks/components/MyLinksIcon";
import { SvgFileIcon } from "core/components/SvgFileIcon";
import { CustomLink } from "core/components/CustomLink";
import { HTMLAttributeAnchorTarget } from "react";
import { Notification } from "../NotificationDefs";
import { NotificationListItemTemplate } from "./NotificationListItemTemplate";

type NotificationListItemProps = Notification & {
  closeMenu: () => Promise<void>;
  listItemProps?: ListItemProps;
};
export function NotificationListItem(props: NotificationListItemProps) {
  const { itemType, listItemProps, isRead, closeMenu, text, id, uri, url } =
    props;
  switch (itemType) {
    case "DmsDocument": {
      const { dynamicData } = props;
      const AvatarIcon =
        dynamicData.type === "file" ? (
          <SvgFileIcon fileName={dynamicData.filename} />
        ) : (
          <MyLinksIcon itemType="External" />
        );
      const href = dynamicData.type === "file" ? uri : url;

      return (
        <NotificationListItemTemplate
          id={id}
          listItemProps={listItemProps}
          listItemButtonProps={{ href: href!, target: "_blank" }}
          AvatarIcon={AvatarIcon}
          isRead={isRead}
          closeMenu={closeMenu}
          text={text}
        />
      );
    }
    case "Event":
      return (
        <NotificationListItemTemplate
          id={id}
          listItemProps={listItemProps}
          AvatarIcon={<MyLinksIcon itemType="Event" />}
          isRead={isRead}
          listItemButtonProps={{ LinkComponent: CustomLink, href: uri! }}
          closeMenu={closeMenu}
          text={text}
        />
      );
    case "News":
      return (
        <NotificationListItemTemplate
          id={id}
          listItemProps={listItemProps}
          AvatarIcon={<MyLinksIcon itemType="News" />}
          isRead={isRead}
          listItemButtonProps={{ LinkComponent: CustomLink, href: uri! }}
          closeMenu={closeMenu}
          text={text}
        />
      );
    case "Page": {
      const href = url ?? uri;
      const target: HTMLAttributeAnchorTarget = url ? "_blank" : "_self";
      return (
        <NotificationListItemTemplate
          id={id}
          listItemProps={listItemProps}
          AvatarIcon={<MyLinksIcon itemType="Page" />}
          isRead={isRead}
          closeMenu={closeMenu}
          listItemButtonProps={{
            LinkComponent: CustomLink,
            href: href!,
            target,
          }}
          text={text}
        />
      );
    }
    default:
      exhaustiveCheck(itemType);
      // fill space in the event of bad data for the VirtualizedList
      return <div style={{ height: "56px" }} />;
  }
}
